import React from "react";
function CommonFooter() {
  return (
    <footer className="ftco-footer ftco-section">
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center"></div>
        </div>
      </div>
    </footer>
  );
}

export default CommonFooter;
