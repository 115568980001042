import React from 'react'
import { SocialIcon } from 'react-social-icons'
function Contact() {
  return (
    <section
    className="ftco-section contact-section ftco-no-pb"
    id="contact-section"
  >
    <div className="container">
      <div className="row justify-content-center mb-5 pb-3">
        <div className="col-md-7 heading-section text-center ">
          <h1 className="big big-2">Contact</h1>
          <h2 className="mb-4">Contact Me</h2>
          {/* <p></p> */}
        </div>
      </div>

      <div className="row d-flex contact-info mb-5">
        <div className="col-md-6 col-lg-4 d-flex ">
          <div className="align-self-stretch box p-4 text-center">
            <div className="icon d-flex align-items-center justify-content-center">
              <span className="icon-map-signs"></span>
            </div>
            <h3 className="mb-4">Address</h3>
            <p>Kitchener, Ontario, Canada</p>
          </div>
        </div>
        <div className="col-md-6 col-lg-4 d-flex ">
          <div className="align-self-stretch box p-4 text-center">
            <div className="icon d-flex align-items-center justify-content-center">
              <span className="icon-calendar"></span>
            </div>
            <h3 className="mb-4">Book a slot</h3>
            <p>
              <a target="_blank" rel="noreferrer" href="https://calendly.com/harjeevan">Calendly</a>
            </p>
          </div>
        </div>
        <div className="col-md-6 col-lg-4 d-flex ">
          <div className="align-self-stretch box p-4 text-center">
            <div className="icon d-flex align-items-center justify-content-center">
              <span className="icon-paper-plane"></span>
            </div>
            <h3 className="mb-4">Email Address</h3>
            <p>
              <a href="mailto:harjeevan.tanda@gmail.com">harjeevan.tanda@gmail.com</a>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div className="container ftco-about">
      <div className="row d-flex">
        <div className="col-md-12">
          <div className="row justify-content-start pb-3">
            <div className="col-md-12 heading-section">
              <h2 className="mb-12">Socials</h2>
              <div className="d-flex flex-wrap justify-content-between">
                <SocialIcon className="social-icon" style={{ height: 150, width: 150 }} target="_blank" url="https://www.linkedin.com/in/harjeevan-tanda" />
                <SocialIcon className="social-icon" style={{ height: 150, width: 150 }} target="_blank" url="https://github.com/hstanda" />
                <SocialIcon className="social-icon" style={{ height: 150, width: 150 }} target="_blank" url="https://twitter.com/hstanda" bgColor="#FFF" fgColor="#000" network="x" />
                <SocialIcon className="social-icon" style={{ height: 150, width: 150 }} target="_blank" url="https://stackoverflow.com/users/4339275/harjeevan-tanda" />
                <SocialIcon className="social-icon" style={{ height: 150, width: 150 }} target="_blank" url="https://t.me/harjeevantanda" />
                <SocialIcon className="social-icon" style={{ height: 150, width: 150 }} target="_blank" url="https://discord.gg/Uu3AFuFhvm" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </section>
  )
}

export default Contact