import React from "react";

function Resume() {
  return (
    <section className="ftco-section ftco-no-pb" id="resume-section">
      <div className="container">
        <div className="row justify-content-center pb-5">
          <div className="col-md-10 heading-section text-center">
            <h1 className="big big-2">Resume</h1>
            <h2 className="mb-4">Resume</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="resume-wrap">
              <span className="date">Jan, 2018 - Today</span>
              <h2>Head of Mobile and Web Development</h2>
              <span className="position">Lynk Remote Technologies LLC</span>
              <p className="mt-4">
                Lead mobile and web development Lead meetings with management
                and other developers Managed outsourced team Involved in
                organizational decisions ReactJS, NojdeJS, Python AWS: Cognito,
                AWS IoT Core, Lambda functions ( NodeJS, Python), <br />
                Dynamodb, Timestream, AWS Kinesis, Microservices, Cloud watch,
                API Gateway endpoints Plan and release mobile applications{" "}
                <br />
                Worked closely with stakeholders and gathered requirements for
                the development team <br />
                Lead mobile app development and backend/web/cloud design which
                involved sprint planning, feature release planning <br />
                Identified and hired new resources and POCs, mockups and designs
                for different solutions <br />
                Technologies: HiveMQ IoT broker, MQTT, PHP Codeigniter,
                Javascript, NodeJS, ExpressJS, Restful APIs, MySQL <br />
                integrations : AT&T dataplans, Stipe, Shopify, Chat, Firebase
                Analytics, High Charts, Gitlab APIs <br />
                Gitlab and terraform automated deployment <br />
                AWS: S3 bucket, EC2, Lambda, SQS, SES, AWS amazon rekognition,
                AWS aws kinesis APIs, CloudWatch, RDS, Secret Manager, <br />
                Identified security issues and implemented solutions <br />
                Hardware integration testing (Raspberry Pi) <br />
                Developed tools for firmware integration testing <br />
                Testing sub-GHz network devices (CC1310) <br />
                Shopify website custom solutions <br />
                Customer support for technical troubleshoot <br />
                Tools used: Monday.com, Joho <br />
              </p>
            </div>
            <div className="resume-wrap">
              <span className="date">2009 - 2013</span>
              <h2>Bachelor of Technology, Computer Science Engineering</h2>
              <span className="position">
                Shaheed Bhagat Singh State Technical Campus, Punjab, India
              </span>
              <p className="mt-4">
                Data Structures & Programming Methodology <br />
                Relational Database management System <br />
                Object-oriented Programming <br />
                Design and analysis of algorithms <br />
                Computer Networks <br />
                Discrete Structures <br />
                Digital Circuits & Logic Design <br />
                Engineering Drawing <br />
              </p>
            </div>
          </div>

          <div className="col-md-6">
            <div className="resume-wrap">
              <span className="date">Jan 2018 - April 2019</span>
              <h2>Mobile Solution Development</h2>
              <span className="position">
                Conestoga College - Kitchener, ON
              </span>
              <p className="mt-4">
                GPA 3.71 <br />
                Recognized as Dean's Honour List award <br />
                Systems Development Life Cycle <br />
                Web Design and Development Principles <br />
                Software Quality Assurance Techniques <br />
                Database Management Microsoft SQL Server <br />
                Mobile Application Development - iOS <br />
                Mobile Application Development - Android <br />
                Computer and Application Security <br />
              </p>
            </div>
            <div className="resume-wrap">
              <span className="date">Nov 2013 - Dec 2017</span>
              <h2>Web Developer / Product Engineer</h2>
              <span className="position">
                Conestoga College - Kitchener, ON
              </span>
              <p className="mt-4">
                In house Project management tool and CRM <br />
                Gathered Project requirements from stakeholders <br />
                Designed and developed systems <br />
                Created restful APIs for mobile apps <br />
                Worked with the QA team & Mobile app development team <br />
                Technologies & tools used: PHP, Jquery, Bootstrap, Codeigniter
                HMVC, MySQL, HTML5, CSS3, Github, AWS EC2, S3 bucket <br />
                Documented the feature and give presentations to developers to
                use the projects <br />
                Developed several eCommerce websites in PHP <br />
                Created Saas Web Applications <br />
                Converted PSD to functional websites <br />
                Communication with clients and requirement gathering <br />
                WordPress, Open cart, Magento <br />
                Developed Multiple projects on php core and PHP Codeigniter
                (HMVC) <br />
                Designed themes and plugins for WordPress websites <br />
                Wocommerce website development <br />
                Payment gateway integrations <br />
                API integrations like Google, Facebook, and Youtube <br />
                Restful APIs <br />
                Database designs <br />
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Resume;
