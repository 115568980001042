import React from 'react'
import OwlCarousel from "react-owl-carousel";

function About() {
  return (
    <>
    <OwlCarousel
    className="owl-theme carouselOuter"
    items={1}
    loop={false}
    margin={10}
    id="home-section"
  >
    <div className="container">
      <div class="item">
        <div className="row" data-scrollax-parent="true">
          <div className="col-md-12 one-forth d-flex  align-items-center">
            <div className="text">
              <span className="subheading">Hello!</span>
              <h1 className="mb-4 mt-3">
                I'm <span>Harjeevan Singh Tanda</span>
              </h1>
              <h2 className="mb-4">Full Stack Developer</h2>
            </div>
          </div>
          {/* <div className="col-md-6">
            <img
              src="/assets/images/harjeevan-full-image.png"
              className="width-full"
              alt="slider"
            />
            <div className="overlay"></div>
          </div> */}
        </div>
      </div>
    </div>
  </OwlCarousel>
  <section className="ftco-about ftco-no-pb" id="about-section">
    <div className="container">
      <div className="row d-flex">
        <div className="col-md-12">
          <div className="row justify-content-start pb-3">
            <div className="col-md-12 heading-section ">
              <h1 className="big">About</h1>
              <h2 className="mb-4">About Me</h2>
              <p>
                With over {new Date().getFullYear() - 2014} years of experience as a full-stack developer, I specialize in delivering robust, scalable, and innovative solutions throughout the software development lifecycle. Starting with a strong passion for coding, I've gained expertise in frontend technologies, backend frameworks, and all areas in between. I am deeply passionate about technology, known for delivering high-quality solutions, and committed to continuous improvement. Experienced in agile environments, I excel in collaborative teamwork, sprint planning, and iterative solution delivery. My frontend skills include proficiency in modern JavaScript frameworks like React.js and Next.js for engaging user interfaces and seamless experiences. On the backend, I work with Python, Node.js, and PHP to develop RESTful APIs, manage databases, and implement scalable server-side logic using frameworks like Codeigniter and Express.js.
              </p>
              {/* <ul className="about-info mt-4 px-md-0 px-2">
                <li className="d-flex">
                  <span>Living in:</span> <span>Ontario Canada</span>
                </li>
                <li className="d-flex">
                  <span>Email:</span> <span>harjeevan.tanda@gmail.com</span>
                </li>
              </ul> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  </>
  )
}

export default About