import React from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Skills from "../components/Skills";
import Services from "../components/Services";
import Contact from "../components/Contact";
import Projects from "../components/Projects";
import About from "../components/About";
import Resume from "../components/Resume";
function HomePage() {
  return (
    <>
      <About></About>
      <Resume></Resume>
      <Services></Services>
      <Projects></Projects>
      <Skills></Skills>
      <Contact></Contact>
    </>
  );
}
export default HomePage;
