import React from "react";
import { Link } from "react-router-dom";

function Services() {
  return (
    <section className="ftco-section" id="services-section">
      <div className="container">
        <div className="row justify-content-center py-5 mt-5">
          <div className="col-md-12 heading-section text-center className">
            <h1 className="big big-2">Services</h1>
            <h2 className="mb-4">Services</h2>
            <p>Contact me if you need help in any of the following</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 text-center d-flex className">
            <Link to="#" className="services-1">
              <span className="icon">
                <i className="flaticon-analysis"></i>
              </span>
              <div className="desc">
                <h3 className="mb-5">Web Design</h3>
              </div>
            </Link>
          </div>

          <div className="col-md-4 text-center d-flex className">
            <Link to="#" className="services-1">
              <span className="icon">
                <i className="flaticon-ideas"></i>
              </span>
              <div className="desc">
                <h3 className="mb-5">Web Developer</h3>
              </div>
            </Link>
          </div>
          <div className="col-md-4 text-center d-flex className">
            <Link to="#" className="services-1">
              <span className="icon">
                <i className="flaticon-flasks"></i>
              </span>
              <div className="desc">
                <h3 className="mb-5">Technical consultation</h3>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Services;
