import React from 'react'
import { Link } from 'react-router-dom'

function Projects() {
  return (
    <section className="ftco-section ftco-project" id="projects-section">
    <div className="container">
      <div className="row justify-content-center pb-5">
        <div className="col-md-12 heading-section text-center ">
          <h1 className="big big-2">Projects</h1>
          <h2 className="mb-4">Our Projects</h2>
          <p>
            Far far away, behind the word mountains, far from the countries
            Vokalia and Consonantia
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <div
            className="project img d-flex justify-content-center align-items-center"
            style={{ backgroundImage: 'url("/assets/images/project-1.jpg")',}}>
            <div className="overlay"></div>
            <div className="text text-center p-4">
              <h3><Link to="https://www.v4designspecialists.com/" target="_blank" rel="noopener noreferrer">V4designspecialists.com</Link></h3>
              <span>PHP &amp; Codeigniter</span>
            </div>
          </div>
        </div>
        <div className="col-md-8">
          <div
            className="project img  d-flex justify-content-center align-items-center"
            style={{ backgroundImage: 'url("/assets/images/project-2.jpg")',}}>
            <div className="overlay"></div>
            <div className="text text-center p-4">
              <h3><Link to="https://assuredefi.com/" target="_blank" rel="noopener noreferrer">assuredefi.com</Link></h3>
              <span>WordPress, Webflow, Crypto, NodeJs</span>
            </div>
          </div>
        </div>

        <div className="col-md-8">
          <div
            className="project img d-flex justify-content-center align-items-center"
            style={{ backgroundImage: 'url("/assets/images/project-3.jpg")',}}>
            <div className="overlay"></div>
            <div className="text text-center p-4">
              <h3><Link to="https://lynkremote.com/" target="_blank" rel="noopener noreferrer" >lynkremote.com</Link></h3>
              <span>IoT, AWS, ReactJS, NodeJs</span>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="row">
            <div className="col-md-12">
              <div
                className="project img d-flex justify-content-center align-items-center"
                style={{ backgroundImage: 'url("/assets/images/project-4.jpg")',}}>
                <div className="overlay"></div>
                <div className="text text-center p-4">
                  <h3><Link to="https://anime.harjeevan.ca/" target="_blank" rel="noopener noreferrer">ReactJS anime listing app</Link></h3>
                  <span>ReactJS Demo App</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}

export default Projects