import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./view/Home";
import Header from "./components/layout/Header";
import CommonFooter from "./components/layout/Footer";
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
        </Routes>
        <CommonFooter />
      </BrowserRouter>
    </div>
  );
}

export default App;
